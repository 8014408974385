<template>
  <in-layout>
    <div slot="list">
      <v-layout class="pa-5" wrap>
        <v-flex xs12 class="mb-3">
          <v-layout justify-space-between align-center>
            <span class="title">Colaboradores</span>
            <create-or-update-employee-dialog />
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <employees-table />
        </v-flex>
      </v-layout>
    </div>
  </in-layout>
</template>

<script>
import AccessIcon from '@/assets/access-icon.svg';
import MyDataIcon from '@/assets/my-data-icon.svg';
import EmployeeIcon from '@/assets/employee-icon.svg';
import NewEmployeeDialog from '@/components/person/employees/CreateOrUpdateEmployeeDialog.vue';
import EmployeesTable from '@/components/settings/EmployeesTable.vue';
import InLayout from '@/layouts/InLayout.vue';

export default {
  name: 'Settings',
  components: {
    CreateOrUpdateEmployeeDialog,
    EmployeesTable,
    InLayout,
  },
  data() {
    return {
      settings: [
        {
          title: 'Empresa',
          subTitle: 'Dados da empresa, licenças e unidades',
          icon: MyDataIcon,
        },
        {
          title: 'Colaboradores',
          subTitle: 'Funcionários cadastrados no sistema',
          icon: EmployeeIcon,
        },
        {
          title: 'Permissões',
          subTitle: 'Perfis e regras de acesso',
          icon: AccessIcon,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.employees-table {
  .v-table__overflow {
    overflow: initial;
  }
  .v-table {
    background: transparent !important;

    thead {
      tr {
        height: 32px;
        border-bottom-color: rgba(0, 0, 0, 0.06) !important;
        border-left-color: transparent;
        border-right-color: transparent;
      }
      th {
        padding: 0 0.5rem !important;
      }
    }
    tbody {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      td {
        position: relative;
      }
    }
    tr {
      position: relative;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
