var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isEmpty(_vm.employees)
    ? _c("v-data-table", {
        staticClass: "employees-table mb-4",
        attrs: {
          headers: _vm.headers,
          items: _vm.employees,
          "no-data-text": "Não existem colaboradores",
          "hide-actions": "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "items",
              fn: function (props) {
                return [
                  _c("td", [
                    _c("span", { staticClass: "body-2" }, [
                      _vm._v(
                        "\n        " + _vm._s(props.item.name) + "\n      "
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _c(
                      "span",
                      {
                        staticClass: "grey--text text--darken-2",
                        attrs: { size: "24" },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.formatCpfCnpj(props.item.cpf)) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "td",
                    {
                      staticClass:
                        "caption text-xs-center grey--text text--darken-2",
                    },
                    [_vm._v("\n      " + _vm._s(props.item.email) + "\n    ")]
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "caption text-xs-center grey--text text--darken-2",
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.getOfficeWithRole(
                              props.item.linked_offices_with_roles
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              },
            },
          ],
          null,
          false,
          614044872
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }