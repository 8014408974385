<template>
  <v-data-table
    class="employees-table mb-4"
    :headers="headers"
    :items="employees"
    no-data-text="Não existem colaboradores"
    hide-actions
    v-if="!isEmpty(employees)"
  >
    <template slot="items" slot-scope="props">
      <td>
        <span class="body-2">
          {{ props.item.name }}
        </span>
      </td>
      <td class="text-xs-center">
        <span size="24" class="grey--text text--darken-2">
          {{ formatCpfCnpj(props.item.cpf) }}
        </span>
      </td>
      <td class="caption text-xs-center grey--text text--darken-2">
        {{ props.item.email }}
      </td>
      <td class="caption text-xs-center grey--text text--darken-2">
         <span v-html="getOfficeWithRole(props.item.linked_offices_with_roles)"></span>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import eventBus from '@/utils/eventBus';
import userService from '@/services/user-service';
import { isEmpty } from 'lodash';

export default {
  name: 'EmployeesTable',
  mounted() {
    this.getEmployeeList();
    eventBus.$on('createdNewEmployee', () => {
      this.getEmployeeList();
    });
  },
  data() {
    return {
      isEmpty,
      formatCpfCnpj,
      isFetchingOffices: true,
      headers: [
        {
          text: 'Nome',
          align: 'left',
          sortable: false,
        },
        {
          text: 'CPF',
          align: 'center',
          sortable: false,
        },
        {
          text: 'E-mail',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Unidade/Permissão',
          align: 'center',
          sortable: false,
        },
      ],
      employees: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
    userRequiredLicenses() {
      const offices = _.get(this.user, 'person.offices', this.currentUser);
      return offices;
    },
  },
  methods: {
    getEmployeeList() {
      userService.getEmployees()
        .then((data) => {
          this.employees = data;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    getOfficeWithRole(linkedOfficesWithRoles) {
      let a = '';
      linkedOfficesWithRoles.forEach((lowr) => {
        a += `${lowr.office.name} (${lowr.office.cpf_cnpj}) / ${lowr.roles.join(', ')} <br> `;
      });
      return a;
    },
  },
};

</script>
