var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("in-layout", [
    _c(
      "div",
      { attrs: { slot: "list" }, slot: "list" },
      [
        _c(
          "v-layout",
          { staticClass: "pa-5", attrs: { wrap: "" } },
          [
            _c(
              "v-flex",
              { staticClass: "mb-3", attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: { "justify-space-between": "", "align-center": "" },
                  },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Colaboradores"),
                    ]),
                    _c("create-or-update-employee-dialog"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-flex", { attrs: { xs12: "" } }, [_c("employees-table")], 1),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }